import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Button, Col, FormGroup, Grid, Row } from "react-bootstrap";
import Card from "../../components/Card/Card";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Storage } from "aws-amplify";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import defaults, {
  DOCUMENT_TEMPLATES,
  DOCUS_CONTENT_ID,
  DOCUS_EDITOR_STATE_ID,
} from "../../defaults";
import { htmlDecode, SetS3Config, toTitleCase } from "../../utils/Utils";
import SaveButton from "./SaveButton";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { ScaleLoader } from "react-spinners";
import { Prompt } from "react-router";
import draftToHtml from "draftjs-to-html";
import AWS from "aws-sdk";
import { Auth } from "@aws-amplify/auth";
import { Base64 } from "js-base64";

const g = defaults.userGroups;
const animatedComponents = makeAnimated();

export default class DocumentEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      currentProject: "",
      currentDocumentType: "",
      availableProjects: null,
      availableDocumentTypes: null,
      allDocumentTypes: null,
      allProjects: null,
      loading: false,
      hasUnsavedChanges: false,
    };
  }

  onChangeCurrentProject(option, action) {
    let availableDocumentTypes =
      option === null
        ? this.state.allDocumentTypes
        : this.state.allDocumentTypes.filter(
            (document) => document.code in option.documents
          );

    this.setState(
      {
        currentProject: option,
        availableDocumentTypes,
      },
      () => this.loadDocumentContentFromS3()
    );
  }

  onChangeCurrentDocumentType(option, action) {
    let availableProjects =
      option === null
        ? this.state.allProjects
        : this.state.allProjects.filter(
            (project) => option.code in project.documents
          );
    this.setState(
      {
        currentDocumentType: option,
        availableProjects,
      },
      () => this.loadDocumentContentFromS3()
    );
  }

  onEditorStateChange(newEditorState) {
    let hasUnsavedChanges = this.state.hasUnsavedChanges;

    // If it is true, no need to compare contents
    if (hasUnsavedChanges === false) {
      hasUnsavedChanges =
        this.state.editorState.getCurrentContent() !==
        newEditorState.getCurrentContent();
    }

    this.setState({
      editorState: newEditorState,
      hasUnsavedChanges: hasUnsavedChanges,
    });
  }

  isDocumentSelected() {
    return Boolean(this.state.currentDocumentType && this.state.currentProject);
  }

  generateFinalHtml(templateData, editorState, htmlContent) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(templateData, "text/html");
    let script = document.createElement("script");
    script.type = "application/json";
    script.id = DOCUS_EDITOR_STATE_ID;
    script.text = JSON.stringify({
      docusEditorState: Base64.encode(JSON.stringify(editorState), true),
    });
    doc.head.appendChild(script);
    let docusContentAnchor = doc.getElementById(DOCUS_CONTENT_ID);
    let docusContent = document.createElement("div");
    docusContent.innerHTML = htmlContent;
    docusContentAnchor.parentNode.replaceChild(
      docusContent,
      docusContentAnchor
    );

    return new XMLSerializer().serializeToString(doc);
  }

  generatePreviewUrls() {
    if (this.isDocumentSelected()) {
      const host =
        this.state.currentProject.host || this.state.currentProject.bucket;
      return this.state.currentProject.documents[
        this.state.currentDocumentType.code
      ].map((document) => `http://${host}/${document.path}`);
    }

    return [];
  }

  uploadToS3(templatePath, documentPath, htmlContent, editorState) {
    return Storage.get(templatePath, {
      download: true,
      customPrefix: { public: "", protected: "", private: "" },
      region: this.state.currentProject.region,
    })
      .then((result) =>
        result.Body.text().then((data) => {
          let renderedHtml = this.generateFinalHtml(
            data,
            editorState,
            htmlContent
          );

          Storage.put(documentPath, renderedHtml, {
            contentType: "text/html",
            customPrefix: { public: "", protected: "", private: "" },
            region: this.state.currentProject.region,
          })
            .then((result) => {
              this.setState({ loading: false, hasUnsavedChanges: false });

              this.props.handleClick(
                `Saved "${this.state.currentProject.name} - ${this.state.currentDocumentType.name}" to "${documentPath}"`,
                "success",
                "tr"
              );
            })
            .catch((error) => {
              this.setState({ loading: false });
              this.props.handleClick(
                `Failed to save document: ${error}`,
                "error",
                "tr"
              );
            });
        })
      )
      .catch((err) => {
        this.setState({
          loading: false,
          editorState: EditorState.createEmpty(),
        });
        this.props.handleClick(
          `Failed to load document: ${err}`,
          "error",
          "tr"
        );
      });
  }

  saveDocument() {
    if (this.isDocumentSelected() && this.state.hasUnsavedChanges) {
      this.setState({ loading: true });
      let htmlContent = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      );
      let editorStateRaw = convertToRaw(
        this.state.editorState.getCurrentContent()
      );
      SetS3Config(this.state.currentProject.bucket);
      for (const document of this.state.currentProject.documents[
        this.state.currentDocumentType.code
      ]) {
        this.uploadToS3(
          document.template ||
            DOCUMENT_TEMPLATES[this.state.currentDocumentType.code],
          document.path,
          htmlContent,
          editorStateRaw
        ).then(() => {
          let params = {
            DistributionId: this.state.currentProject
              .cloudfront_distribution_id,
            InvalidationBatch: {
              CallerReference: new Date().toISOString(),
              Paths: {
                Quantity: 1,
                Items: [`/${document.path}`],
              },
            },
          };
          Auth.currentUserCredentials()
            .then((data) => {
              let cloudfront = new AWS.CloudFront({ credentials: data });
              cloudfront.createInvalidation(params, (err, data) => {
                if (err)
                  this.props.handleClick(
                    `Failed to create invalidation: ${err}`,
                    "error",
                    "tr"
                  );
              });

              // let s3 = new S3({ region: "us-east-2", credentials: data });
              // s3.getBucketLocation({
              //   Bucket: this.state.currentProject.bucket,
              // })
              //   .then((data) => console.log("BUCKET", data))
              //   .catch((err) =>
              //     console.log("Failed to get bucket region", err)
              //   );
            })
            .catch((err) => {
              this.props.handleClick(
                `Failed to get credentials: ${err}`,
                "error",
                "tr"
              );
            });
        });
      }
    }
  }

  loadDocumentContentFromS3() {
    if (this.isDocumentSelected()) {
      this.setState({ loading: true });
      let documentPath = this.state.currentProject.documents[
        this.state.currentDocumentType.code
      ][0].path;

      SetS3Config(this.state.currentProject.bucket);
      Storage.get(documentPath, {
        download: true,
        customPrefix: { public: "", protected: "", private: "" },
        region: this.state.currentProject.region,
      })
        .then((result) =>
          result.Body.text().then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            let docusContent = doc.getElementById(DOCUS_EDITOR_STATE_ID);
            let newContentState = EditorState.createEmpty().getCurrentContent();
            if (docusContent) {
              let payload = JSON.parse(docusContent.text);
              let base64Data = payload.docusEditorState;
              if (base64Data !== undefined) {
                payload = JSON.parse(Base64.decode(base64Data));
              }
              payload = JSON.stringify(payload);
              for (let i = 0; i < 10; i++) {
                payload = htmlDecode(payload);
              }
              newContentState = convertFromRaw(JSON.parse(payload));
            }
            const editorState = EditorState.push(
              this.state.editorState,
              newContentState
            );
            this.setState({ editorState, loading: false });
          })
        )
        .catch((err) => {
          this.setState({
            loading: false,
            editorState: EditorState.createEmpty(),
          });
          console.error(err);
          this.props.handleClick(
            `Failed to load document: ${err}`,
            "error",
            "tr"
          );
        });
    }
  }

  componentDidUpdate() {
    if (this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentWillMount() {
    let allProjects = Object.values(defaults.projects).filter(
      (x) => x.documents !== undefined
    );

    let documentTypesUniq = [
      ...new Set(
        Object.values(defaults.projects)
          .map((x) => Object.keys(x.documents || {}))
          .flat()
      ),
    ];
    let allDocumentTypes = documentTypesUniq.map((x) => {
      return { code: x, name: toTitleCase(x) };
    });
    this.setState({
      availableProjects: allProjects,
      availableDocumentTypes: allDocumentTypes,
      allDocumentTypes,
      allProjects,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Prompt
          when={this.state.hasUnsavedChanges}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title={"Document Editor"}
                  content={
                    <Row>
                      <Col md={this.props.md || 12}>
                        <FormGroup>
                          <Col md={3}>
                            <Select
                              onChange={(option, action) =>
                                this.onChangeCurrentProject(option, action)
                              }
                              placeholder={"Select Project"}
                              isClearable={true}
                              name={"Project"}
                              value={this.state.currentProject}
                              options={this.state.availableProjects}
                              isLoading={false}
                              components={animatedComponents}
                              closeMenuOnSelect={true}
                              getOptionLabel={(option) => {
                                return option.name;
                              }}
                              getOptionValue={(option) => {
                                return option.name;
                              }}
                            />
                          </Col>
                          <Col md={3}>
                            <Select
                              onChange={(option, action) =>
                                this.onChangeCurrentDocumentType(option, action)
                              }
                              placeholder={"Select Document Type"}
                              isClearable={true}
                              name={"DocumentType"}
                              value={this.state.currentDocumentType}
                              options={this.state.availableDocumentTypes}
                              isLoading={false}
                              components={animatedComponents}
                              closeMenuOnSelect={true}
                              getOptionLabel={(option) => {
                                return option.name;
                              }}
                              getOptionValue={(option) => {
                                return option.name;
                              }}
                            />
                          </Col>
                          <Col md={1}>
                            <Button
                              bsStyle="success"
                              type="button"
                              disabled={!this.isDocumentSelected()}
                              onClick={(event) =>
                                this.generatePreviewUrls().map((url) =>
                                  window.open(url, "_blank", "PopUp", url)
                                )
                              }
                            >
                              Preview
                            </Button>
                          </Col>
                          <Col md={1}>
                            <div className="sweet-loading">
                              <ScaleLoader
                                height={30}
                                color={"#1DC7EA"}
                                loading={this.state.loading}
                              />
                            </div>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                />
                <Card
                  content={
                    <Editor
                      wrapperClassName="textedit-wrapper"
                      editorClassName="textedit-editor"
                      toolbarClassName="textedit-toolbar"
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "emoji",
                          "image",
                          "remove",
                          "history",
                        ],
                      }}
                      onEditorStateChange={(editorState) =>
                        this.onEditorStateChange(editorState)
                      }
                      editorState={this.state.editorState}
                      toolbarCustomButtons={
                        this.props.userHasPermission([g.admin, g.editor])
                          ? [
                              <SaveButton
                                onClick={() => this.saveDocument()}
                                disabled={!this.state.hasUnsavedChanges}
                              />,
                            ]
                          : []
                      }
                    />
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
