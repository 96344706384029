module.exports = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>FAQ | Select Section</title>
    <meta name="description" content="">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
    <meta name="description" content="">

    <link rel="shortcut icon" href="../img/favicon/favicon.ico" type="image/x-icon">
    <link rel="apple-touch-icon" href="../img/favicon/apple-touch-icon.png">
    <link rel="apple-touch-icon" sizes="72x72" href="../img/favicon/apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="114x114" href="../img/favicon/apple-touch-icon-114x114.png">

    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">

    <link rel="stylesheet" href="../libs/bootstrap/css/bootstrap-grid.min.css">
    <link rel="stylesheet" href="../css/main.css?v6">
    <style type="text/css">
        a {
            text-decoration: underline;
        }
    </style>
</head>
<body>
<div class="main">
    <section>
        <div class="container">
            <!-- Content here -->
            <div id="docus-content"></div>
            <!-- Content here -->
        </div>
    </section>
</div>
</body>
</html>`;
