import React, { Component } from "react";
import { Col, ControlLabel, FormGroup, Grid, Row } from "react-bootstrap";
import Card from "../../components/Card/Card";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Storage } from "aws-amplify";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import defaults, {
  DOCUS_CONTENT_ID,
  DOCUS_FAQ_SECTIONS_ID,
  FAQ_SECTIONS_ROOT_DOCUMENT,
  INDEX_HTML,
} from "../../defaults";
import { SetS3Config } from "../../utils/Utils";
import { ScaleLoader } from "react-spinners";
import { Prompt } from "react-router";
import TableCollection from "../../components/Form/TableCollection";
import SimpleReactValidator from "simple-react-validator";
import Button from "../../components/CustomButton/CustomButton";
import sectionsTemplate from "./Templates/Sections";
import { renderToString } from "react-dom/server";
import slugify from "slugify";
import { NavLink } from "react-router-dom";

const animatedComponents = makeAnimated();

export default class SectionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProject: "",
      availableProjects: null,
      allProjects: null,
      loading: false,
      hasUnsavedChanges: false,
      sectionsAll: [],
      sectionsEditingState: [],
      resourceData: {},
    };

    this.validator = new SimpleReactValidator({ className: "text-danger" });
  }

  onChangeCurrentProject(option, action) {
    this.setState(
      {
        currentProject: option,
      },
      () => this.loadFaqSections()
    );
  }

  getFaqRoot() {
    return (
      this.state.currentProject.faq?.sections_root || FAQ_SECTIONS_ROOT_DOCUMENT
    );
  }

  isDocumentSelected() {
    return Boolean(this.state.currentProject);
  }

  convertSectionsToHtml(sections) {
    return renderToString(
      sections.map((section) => (
        <h3>
          <a href={`${section.slug}/${INDEX_HTML}`}>{section.name}</a>
        </h3>
      ))
    );
  }

  getSlugifiedSections() {
    let result = {};

    for (const sectionOrig of this.state.sectionsEditingState) {
      let section = Object.assign({}, sectionOrig);
      if (section.slug === undefined) {
        section.slug = slugify(section.name, { strict: true, lower: true });
      }

      if (result.hasOwnProperty(section.slug)) {
        let duplicate = result[section.slug];
        this.props.handleClick(
          `Sections: "${section.name}" and "${duplicate.name}" have identical slugs: 
          "${section.slug}" and "${duplicate.slug}. Consider using a different name"`,
          "error",
          "tr"
        );
        return;
      }

      result[section.slug] = section;
    }

    return Object.values(result);
  }

  generateFinalHtml(sections) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(sectionsTemplate, "text/html");
    let script = document.createElement("script");
    script.type = "application/json";
    script.id = DOCUS_FAQ_SECTIONS_ID;
    script.text = JSON.stringify(sections);
    doc.head.appendChild(script);
    let docusContentAnchor = doc.getElementById(DOCUS_CONTENT_ID);
    let docusContent = document.createElement("div");
    docusContent.innerHTML = this.convertSectionsToHtml(sections);
    docusContentAnchor.parentNode.replaceChild(
      docusContent,
      docusContentAnchor
    );

    return new XMLSerializer().serializeToString(doc);
  }

  loadFaqSections() {
    if (this.isDocumentSelected()) {
      this.setState({ loading: true });
      let documentPath = `${this.getFaqRoot()}/${INDEX_HTML}`;

      SetS3Config(this.state.currentProject.bucket);
      Storage.get(documentPath, {
        download: true,
        customPrefix: { public: "", protected: "", private: "" },
        region: this.state.currentProject.region,
      })
        .then((result) =>
          result.Body.text().then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            let faqSections = doc.getElementById(DOCUS_FAQ_SECTIONS_ID);
            if (faqSections) {
              faqSections = JSON.parse(faqSections.text);
            } else {
              faqSections = [];
            }

            this.setState({
              sectionsAll: faqSections,
              sectionsEditingState: faqSections,
              loading: false,
            });
          })
        )
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.error(err);
          this.props.handleClick(
            `Failed to load FAQ sections: ${err}`,
            "error",
            "tr"
          );
        });
    }
  }

  saveFaqSections() {
    SetS3Config(this.state.currentProject.bucket);
    let documentPath = `${this.getFaqRoot()}/${INDEX_HTML}`;

    let sections = this.getSlugifiedSections();

    if (!sections) {
      return;
    }

    this.setState({ loading: true });

    let renderedHtml = this.generateFinalHtml(sections);

    console.log("renderedHtml", renderedHtml);

    Storage.put(documentPath, renderedHtml, {
      contentType: "text/html",
      customPrefix: { public: "", protected: "", private: "" },
      region: this.state.currentProject.region,
    })
      .then((result) => {
        this.setState({
          loading: false,
          hasUnsavedChanges: false,
          sectionsEditingState: sections,
        });

        this.props.handleClick(
          `Saved "${this.state.currentProject.name} - FAQ Sections" to "${documentPath}"`,
          "success",
          "tr"
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.props.handleClick(
          `Failed to save document: ${error}`,
          "error",
          "tr"
        );
      });
  }

  handleCollection = (_State, name) => {
    console.log("Sections", _State);
    this.setState({ sectionsEditingState: _State, hasUnsavedChanges: true });
  };

  componentWillMount() {
    let allProjects = Object.keys(defaults.projects)
      .filter((key) => defaults.projects[key].faq?.enabled === true)
      .map((x) => {
        return { name: x, ...defaults.projects[x] };
      });

    this.setState({
      availableProjects: allProjects,
      allProjects,
    });
  }

  componentDidUpdate() {
    if (this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  render() {
    return (
      <React.Fragment>
        <Prompt
          when={this.state.hasUnsavedChanges}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title={"FAQ Sections Editor"}
                  content={
                    <Row>
                      <Col md={this.props.md || 12}>
                        <FormGroup>
                          <Col md={3}>
                            <Select
                              onChange={(option, action) =>
                                this.onChangeCurrentProject(option, action)
                              }
                              placeholder={"Select Project"}
                              isClearable={true}
                              name={"Project"}
                              value={this.state.currentProject}
                              options={this.state.availableProjects}
                              isLoading={false}
                              components={animatedComponents}
                              closeMenuOnSelect={true}
                              getOptionLabel={(option) => {
                                return option.name;
                              }}
                              getOptionValue={(option) => {
                                return option.name;
                              }}
                            />
                          </Col>
                          <Col md={1}>
                            <div className="sweet-loading">
                              <ScaleLoader
                                height={30}
                                color={"#1DC7EA"}
                                loading={this.state.loading}
                              />
                            </div>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                />
                <Card
                  content={
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <ControlLabel>{"FAQ Sections"}</ControlLabel>
                          <TableCollection
                            disabled={!this.isDocumentSelected()}
                            name={"collection"}
                            prototype={{
                              name: {
                                type: "input",
                                validationRules: "required",
                                value: "",
                                md: 12,
                              },
                            }}
                            value={this.state.sectionsEditingState}
                            onChange={this.handleCollection}
                            validator={this.validator}
                            actions={[
                              (rowObject, item) => {
                                return (
                                  <Button
                                    block
                                    bsStyle="danger"
                                    className="btn btn-danger"
                                    onClick={(e) => {
                                      this.setState(
                                        {
                                          hasUnsavedChanges: true,
                                        },
                                        () => {
                                          rowObject.props.onDelete(
                                            e,
                                            rowObject.props.index
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    Delete
                                  </Button>
                                );
                              },
                              (rowObject, item) => {
                                if (item.slug === undefined) {
                                  return undefined;
                                }
                                return (
                                  <NavLink
                                    className="btn btn-success"
                                    to={{
                                      pathname: `/faq/${this.state.currentProject.name}/${item.slug}`,
                                      state: {
                                        section: item,
                                      },
                                    }}
                                  >
                                    Questions
                                  </NavLink>
                                );
                              },
                            ]}
                          />
                        </FormGroup>
                        <Button
                          disabled={!this.isDocumentSelected()}
                          bsStyle="primary"
                          type="submit"
                          onClick={(e) => this.saveFaqSections()}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
