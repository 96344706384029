import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorState, Modifier } from "draft-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

export default class SaveButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    editorState: PropTypes.object,
    disabled: PropTypes.bool,
  };

  addStar = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      "⭐",
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  render() {
    const btnClasses = cx({
      "rdw-option-disabled": this.props.disabled || false,
      "rdw-save-wrapper": true,
    });
    return (
      <div
        className={btnClasses}
        aria-label={"rdw-save-control"}
        onClick={this.props.onClick}
      >
        <div className={"rdw-option-wrapper"} title={"Save"}>
          <FontAwesomeIcon
            icon={faSave}
            color={this.props.disabled ? undefined : "red"}
          />
        </div>
      </div>
    );
  }
}
