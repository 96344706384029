export const DOCUMENT_TYPES = {
  TERMS_OF_USE: "terms_of_use",
  PRIVACY_POLICY: "privacy_policy",
  REFUND_POLICY: "refund_policy",
  MIRROR_TERMS_OF_USE: "mirror_terms_of_use",
  MIRROR_PRIVACY_POLICY: "mirror_privacy_policy",
  MIRROR_REFUND_POLICY: "mirror_refund_policy",
  REVIEW_TERMS_OF_USE: "review_terms_of_use",
  REVIEW_PRIVACY_POLICY: "review_privacy_policy",
  REVIEW_REFUND_POLICY: "review_refund_policy",
  TERMS_OF_USE_ANDROID: "terms_of_use_android",
  SUBSCRIPTION_TERMS: "subscription_terms",
  SUBSCRIPTION_TERMS_ANDROID: "subscription_terms_android",
  SUBSCRIPTION_TERMS_HUAWEI: "subscription_terms_huawei",
  PRIVACY_POLICY_ANDROID: "privacy_policy_android",
  FAQ: "faq",
};

export const DOCUS_EDITOR_STATE_ID = "docus-editor-state";
export const DOCUS_FAQ_SECTIONS_ID = "docus-faq-sections";
export const DOCUS_CONTENT_ID = "docus-content";

export const FAQ_SECTIONS_ROOT_DOCUMENT = "faq";
export const INDEX_HTML = "index.html";

export const DOCUMENT_TEMPLATES = {
  [DOCUMENT_TYPES.PRIVACY_POLICY]: "privacy-policy-template.html",
  [DOCUMENT_TYPES.PRIVACY_POLICY_ANDROID]: "privacy-policy-template.html",
  [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: "subscription-terms-template.html",
  [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]:
    "subscription-terms-template.html",
  [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_HUAWEI]:
    "subscription-terms-template.html",
  [DOCUMENT_TYPES.TERMS_OF_USE]: "terms-of-use-template.html",
  [DOCUMENT_TYPES.TERMS_OF_USE_ANDROID]: "terms-of-use-template.html",
  [DOCUMENT_TYPES.FAQ]: "faq-template.html"
};

export default {
  app: {
    name: "Docus",
  },
  userGroups: {
    admin: "admin",
    viewer: "viewer",
    editor: "editor",
    support: "support",
  },
  projects: {
    scannix: {
      name: "Scanner (Scannix)",
      host: "scanix.net",
      bucket: "static-scanner-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E1PI4K81YXTPF0",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-ios.html",
            template: "subscription-terms-ios-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use.html",
          },
        ],
      },
    },
    calorietracker_prod: {
      name: "CalorieTracker APP PROD (Stay on Track, SoT)",
      host: "stay-on-track.io",
      bucket: "react-calorietracker-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E16YYNER7ZO9L3",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "subscription-terms-android.html",
          },
        ],
      },
    },
    calorietracker_stage: {
      name: "CalorieTracker APP STAGE (Stay on Track, SoT)",
      host: "calorietracker-landing-stage.asqq.io",
      bucket: "react-calorietracker-landing-stage",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YVX076YGXCFJ",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "subscription-terms-android.html",
          },
        ],
      },
    },
    calorietracker_web_prod: {
      name: "CalorieTracker WEB PROD (Stay On Track, SoT)",
      bucket: "react-calorietracker-web-prod",
      host: "get-on-track.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1I4I0O90G1308",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    running: {
      name: "Running (Raniac)",
      host: "weight-loss-running.com",
      bucket: "static-running-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E2F9WOTT76A8RU",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "android/privacy-policy-app.html",
          },
          {
            path: "ios/privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "ios/subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "android/subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "android/terms-of-use-app.html",
          },
          {
            path: "ios/terms-of-use-app.html",
          },
        ],
      },
    },
    run_prod: {
      name: "Run PROD",
      host: "running-training-coach.com",
      bucket: "static-run-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E3BWDL0C8XPKZZ",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "android/privacy-policy-app.html",
          },
          {
            path: "ios/privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "ios/subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "android/subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "android/terms-of-use-app.html",
          },
          {
            path: "ios/terms-of-use-app.html",
          },
        ],
      },
    },
    run_stage: {
      name: "Run STAGE",
      host: "run-landing-stage.asqq.io",
      bucket: "static-run-landing-stage",
      region: "eu-central-1",
      cloudfront_distribution_id: "E3T9JSJZPZ3S21",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "android/privacy-policy-app.html",
          },
          {
            path: "ios/privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "ios/subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "android/subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "android/terms-of-use-app.html",
          },
          {
            path: "ios/terms-of-use-app.html",
          },
        ],
      },
    },
    beyoga: {
      name: "BeYoga (BeYoga)",
      host: "beyoga.app",
      bucket: "static-beyoga-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E1DF37BRH8UWB2",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "subscription-terms-android.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use-app.html",
          },
        ],
      },
    },
    videoeditor: {
      name: "VideoEditor (YaVi)",
      host: "yavitech.com",
      bucket: "static-videoeditor-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E3TCOSFLAXNY56",
      faq: {
        enabled: false,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use.html",
          },
        ],
      },
    },
    bettersleeptracker: {
      name: "Meditation (bettersleeptracker.com)",
      host: "bettersleeptracker.com",
      bucket: "static-meditation-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E1NOTIYKLDT4D4",
      faq: {
        enabled: false,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy-app.html",
          },
          {
            path: "privacy-policy.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use-app.html",
          },
          {
            path: "terms-of-use.html",
          },
        ],
      },
    },
    yogago: {
      name: "Yoga-Go (yoga-go.fit)",
      host: "yoga-go.fit",
      bucket: "static-yoga-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E2GDNPC6IGZ7S5",
      faq: {
        enabled: false,
      },
      documents: {
        [DOCUMENT_TYPES.FAQ]: [
          {
            path: "web/faq.html"
          },
        ],
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "an/privacy-policy-app.html",
          },
          {
            path: "an/privacy-policy.html",
          },
          {
            path: "privacy-policy-app.html",
          },
          {
            path: "privacy-policy.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-ios.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "subscription-terms.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "an/terms-of-use-app.html",
          },
          {
            path: "an/terms-of-use.html",
          },
          {
            path: "terms-of-use-app.html",
          },
          {
            path: "terms-of-use.html",
          },
        ],
      },
    },
    tongo: {
      name: "Tongo (lango-app.com)",
      host: "lango-app.com",
      bucket: "static-engular-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E8F78C0SK5D2I",
      faq: {
        enabled: false,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "subscription-terms-android.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_HUAWEI]: [
          {
            path: "subscription-terms-huawei.html",
          },
        ],
      },
    },
    running_web: {
      name: "Running(WEB)",
      bucket: "react-running-front-prod",
      host: "move-to-goal.com",
      region: "us-east-2",
      cloudfront_distribution_id: "E2O0ZAWJNEZ9DF",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_PRIVACY_POLICY]: [
          {
            path: "policy/mirror-privacy-policy.html",
            template: "mirror-privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_TERMS_OF_USE]: [
          {
            path: "policy/mirror-terms-of-use.html",
            template: "mirror-terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_REFUND_POLICY]: [
          {
            path: "policy/mirror-refund-policy.html",
            template: "mirror-refund-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_PRIVACY_POLICY]: [
          {
            path: "policy/review-privacy-policy.html",
            template: "review-privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_TERMS_OF_USE]: [
          {
            path: "policy/review-terms-of-use.html",
            template: "review-terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_REFUND_POLICY]: [
          {
            path: "policy/review-refund-policy.html",
            template: "review-refund-policy-template.html",
          },
        ],
      },
    },
    running_web_develop: {
      name: "Running(WEB DEVELOP)",
      bucket: "react-running-front-develop",
      host: "running-front-develop.asqq.io",
      region: "eu-central-1",
      cloudfront_distribution_id: "E3I8PWRHAMNF3W",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_PRIVACY_POLICY]: [
          {
            path: "policy/mirror-privacy-policy.html",
            template: "mirror-privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_TERMS_OF_USE]: [
          {
            path: "policy/mirror-terms-of-use.html",
            template: "mirror-terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_REFUND_POLICY]: [
          {
            path: "policy/mirror-refund-policy.html",
            template: "mirror-refund-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_PRIVACY_POLICY]: [
          {
            path: "policy/review-privacy-policy.html",
            template: "review-privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_TERMS_OF_USE]: [
          {
            path: "policy/review-terms-of-use.html",
            template: "review-terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_REFUND_POLICY]: [
          {
            path: "policy/review-refund-policy.html",
            template: "review-refund-policy-template.html",
          },
        ],
      },
    },
    mycoach_web: {
      name: "Fitcoach(WEB)",
      bucket: "react-mycoach-front-prod",
      host: "dailyfit.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E12Q9JC7PFEI1I",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_PRIVACY_POLICY]: [
          {
            path: "policy/mirror-privacy-policy.html",
            template: "mirror-privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_TERMS_OF_USE]: [
          {
            path: "policy/mirror-terms-of-use.html",
            template: "mirror-terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_REFUND_POLICY]: [
          {
            path: "policy/mirror-refund-policy.html",
            template: "mirror-refund-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_PRIVACY_POLICY]: [
          {
            path: "policy/review-privacy-policy.html",
            template: "review-privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_TERMS_OF_USE]: [
          {
            path: "policy/review-terms-of-use.html",
            template: "review-terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_REFUND_POLICY]: [
          {
            path: "policy/review-refund-policy.html",
            template: "review-refund-policy-template.html",
          },
        ],
      },
    },
    fasting_web: {
      name: "Fasting(WEB)",
      bucket: "react-fasting-front-prod",
      host: "fasteasy.io",
      region: "us-east-2",
      cloudfront_distribution_id: "EHPADMCPLLRNO",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    fasting_mirrors: {
      name: "Fasting Mirrors",
      bucket: "react-fasting-front-mirrors-prod-872604341275",
      host: "fasteasywomen.com",
      region: "us-east-2",
      cloudfront_distribution_id: "E3GZDEG4T6Y7X6",
      faq: {
        enabled: true,
      },
      documents: {
        "fasteasygirl_privacy_policy": [
          {
            path: "policy/fasteasygirl-privacy-policy.html",
            template: "fasteasygirl-privacy-policy-template.html",
          },
        ],
        "fasteasygirl_terms_of_use": [
          {
            path: "policy/fasteasygirl-terms-of-use.html",
            template: "fasteasygirl-terms-of-use-template.html",
          },
        ],
        "fasteasygirl_refund_policy": [
          {
            path: "policy/fasteasygirl-refund-policy.html",
            template: "fasteasygirl-refund-policy-template.html",
          },
        ],
        "fasteasywomen_privacy_policy": [
          {
            path: "policy/fasteasywomen-privacy-policy.html",
            template: "fasteasywomen-privacy-policy-template.html",
          },
        ],
        "fasteasywomen_terms_of_use": [
          {
            path: "policy/fasteasywomen-terms-of-use.html",
            template: "fasteasywomen-terms-of-use-template.html",
          },
        ],
        "fasteasywomen_refund_policy": [
          {
            path: "policy/fasteasywomen-refund-policy.html",
            template: "fasteasywomen-refund-policy-template.html",
          },
        ],
        "mensfasting_privacy_policy": [
          {
            path: "policy/mensfasting-privacy-policy.html",
            template: "mensfasting-privacy-policy-template.html",
          },
        ],
        "mensfasting_terms_of_use": [
          {
            path: "policy/mensfasting-terms-of-use.html",
            template: "mensfasting-terms-of-use-template.html",
          },
        ],
        "mensfasting_refund_policy": [
          {
            path: "policy/mensfasting-refund-policy.html",
            template: "mensfasting-refund-policy-template.html",
          },
        ],
        "fastingboys_privacy_policy": [
          {
            path: "policy/fastingboys-privacy-policy.html",
            template: "fastingboys-privacy-policy-template.html",
          },
        ],
        "fastingboys_terms_of_use": [
          {
            path: "policy/fastingboys-terms-of-use.html",
            template: "fastingboys-terms-of-use-template.html",
          },
        ],
        "fastingboys_refund_policy": [
          {
            path: "policy/fastingboys-refund-policy.html",
            template: "fastingboys-refund-policy-template.html",
          },
        ],
        "muscularfast_privacy_policy": [
          {
            path: "policy/muscularfast-privacy-policy.html",
            template: "muscularfast-privacy-policy-template.html",
          },
        ],
        "muscularfast_terms_of_use": [
          {
            path: "policy/muscularfast-terms-of-use.html",
            template: "muscularfast-terms-of-use-template.html",
          },
        ],
        "muscularfast_refund_policy": [
          {
            path: "policy/muscularfast-refund-policy.html",
            template: "muscularfast-refund-policy-template.html",
          },
        ],
        "plusfasting_privacy_policy": [
          {
            path: "policy/plusfasting-privacy-policy.html",
            template: "plusfasting-privacy-policy-template.html",
          },
        ],
        "plusfasting_terms_of_use": [
          {
            path: "policy/plusfasting-terms-of-use.html",
            template: "plusfasting-terms-of-use-template.html",
          },
        ],
        "plusfasting_refund_policy": [
          {
            path: "policy/plusfasting-refund-policy.html",
            template: "plusfasting-refund-policy-template.html",
          },
        ],
        "pluswomenfast_privacy_policy": [
          {
            path: "policy/pluswomenfast-privacy-policy.html",
            template: "pluswomenfast-privacy-policy-template.html",
          },
        ],
        "pluswomenfast_terms_of_use": [
          {
            path: "policy/pluswomenfast-terms-of-use.html",
            template: "pluswomenfast-terms-of-use-template.html",
          },
        ],
        "pluswomenfast_refund_policy": [
          {
            path: "policy/pluswomenfast-refund-policy.html",
            template: "pluswomenfast-refund-policy-template.html",
          },
        ],
        "womenstartfasting_privacy_policy": [
          {
            path: "policy/womenstartfasting-privacy-policy.html",
            template: "womenstartfasting-privacy-policy-template.html",
          },
        ],
        "womenstartfasting_terms_of_use": [
          {
            path: "policy/womenstartfasting-terms-of-use.html",
            template: "womenstartfasting-terms-of-use-template.html",
          },
        ],
        "womenstartfasting_refund_policy": [
          {
            path: "policy/womenstartfasting-refund-policy.html",
            template: "womenstartfasting-refund-policy-template.html",
          },
        ],
        "plusmenfast_privacy_policy": [
          {
            path: "policy/plusmenfast-privacy-policy.html",
            template: "plusmenfast-privacy-policy-template.html",
          },
        ],
        "plusmenfast_terms_of_use": [
          {
            path: "policy/plusmenfast-terms-of-use.html",
            template: "plusmenfast-terms-of-use-template.html",
          },
        ],
        "plusmenfast_refund_policy": [
          {
            path: "policy/plusmenfast-refund-policy.html",
            template: "plusmenfast-refund-policy-template.html",
          },
        ],
        "fastslimgirl_privacy_policy": [
          {
            path: "policy/fastslimgirl-privacy-policy.html",
            template: "fastslimgirl-privacy-policy-template.html",
          },
        ],
        "fastslimgirl_terms_of_use": [
          {
            path: "policy/fastslimgirl-terms-of-use.html",
            template: "fastslimgirl-terms-of-use-template.html",
          },
        ],
        "fastslimgirl_refund_policy": [
          {
            path: "policy/fastslimgirl-refund-policy.html",
            template: "fastslimgirl-refund-policy-template.html",
          },
        ],
      },
    },
    mycoach_web_develop: {
      name: "Fitcoach(WEB DEVELOP)",
      bucket: "react-mycoach-front-develop",
      host: "mycoach-front-develop.asqq.io",
      region: "eu-central-1",
      cloudfront_distribution_id: "E23YSE7BCRZIKJ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_PRIVACY_POLICY]: [
          {
            path: "policy/mirror-privacy-policy.html",
            template: "mirror-privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_TERMS_OF_USE]: [
          {
            path: "policy/mirror-terms-of-use.html",
            template: "mirror-terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.MIRROR_REFUND_POLICY]: [
          {
            path: "policy/mirror-refund-policy.html",
            template: "mirror-refund-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_PRIVACY_POLICY]: [
          {
            path: "policy/review-privacy-policy.html",
            template: "review-privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_TERMS_OF_USE]: [
          {
            path: "policy/review-terms-of-use.html",
            template: "review-terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REVIEW_REFUND_POLICY]: [
          {
            path: "policy/review-refund-policy.html",
            template: "review-refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_develop: {
      name: "MB(WEB DEVELOP)",
      bucket: "react-menscoach-front-develop",
      host: "menscoach-front-develop.asqq.io",
      region: "eu-central-1",
      cloudfront_distribution_id: "E2RK5UZ2YA9LFF",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror1: {
      name: "MB(WEB MIRROR) track-fit.io",
      bucket: "react-menscoach-front-prod",
      host: "track-fit.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/track-fit-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/track-fit-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/track-fit-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror2: {
      name: "MB(WEB MIRROR) spacefit.io",
      bucket: "react-menscoach-front-prod",
      host: "spacefit.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/spacefit-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/spacefit-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/spacefit-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror3: {
      name: "MB(WEB MIRROR) fitnessme.io",
      bucket: "react-menscoach-front-prod",
      host: "fitnessme.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/fitnessme-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/fitnessme-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/fitnessme-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror4: {
      name: "MB(WEB MIRROR) mydailyfit.io",
      bucket: "react-menscoach-front-prod",
      host: "mydailyfit.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/mydailyfit-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/mydailyfit-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/mydailyfit-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror5: {
      name: "MB(WEB MIRROR) getfitme.io",
      bucket: "react-menscoach-front-prod",
      host: "getfitme.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/getfitme-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/getfitme-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/getfitme-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror6: {
      name: "MB(WEB MIRROR) fitness2day.io",
      bucket: "react-menscoach-front-prod",
      host: "fitness2day.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/fitness2day-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/fitness2day-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/fitness2day-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror7: {
      name: "MB(WEB MIRROR) track-and-fit.com",
      bucket: "react-menscoach-front-prod",
      host: "track-and-fit.com",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/track-and-fit-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/track-and-fit-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/track-and-fit-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror8: {
      name: "MB(WEB MIRROR) myhealthfit.io",
      bucket: "react-menscoach-front-prod",
      host: "myhealthfit.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/myhealthfit-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/myhealthfit-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/myhealthfit-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror9: {
      name: "MB(WEB MIRROR) myfitnesspace.io",
      bucket: "react-menscoach-front-prod",
      host: "myfitnesspace.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/myfitnesspace-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/myfitnesspace-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/myfitnesspace-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror10: {
      name: "MB(WEB MIRROR) go4fit.io",
      bucket: "react-menscoach-front-prod",
      host: "go4fit.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/go4fit-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/go4fit-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/go4fit-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    mb_web_mirror11: {
      name: "MB(WEB MIRROR) fitpoint.io",
      bucket: "react-menscoach-front-prod",
      host: "fitpoint.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E1YK4B712BMLNZ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/fitpoint-privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/fitpoint-terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/fitpoint-refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    tongo_web: {
      name: "HealthLab(WEB)",
      bucket: "react-artlab-front-prod",
      host: "wellcube.pro",
      region: "us-east-2",
      cloudfront_distribution_id: "ENXM9X7OLL4UJ",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
        [DOCUMENT_TYPES.REFUND_POLICY]: [
          {
            path: "policy/refund-policy.html",
            template: "refund-policy-template.html",
          },
        ],
      },
    },
    Test: {
      name: "Test",
      bucket: "docus-test",
      host: "d23o425xfoynj8.cloudfront.net",
      region: "us-east-1",
      cloudfront_distribution_id: "E3O2XUAE0YAYL4",
      faq: {
        enabled: true,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-ios.html",
            template: "subscription-terms-ios-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use.html",
          },
          {
            path: "terms-of-use-test.html",
          },
        ],
      },
    },
    piano: {
      name: "Amazing Piano (amazing-piano.com)",
      host: "amazing-piano.com",
      bucket: "static-piano-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E5NBEZ22GMJ9A",
      faq: {
        enabled: false,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use-app.html",
          },
        ],
      },
    },
    groovy_loops: {
      name: "Groovy Loops (groovyloops.app)",
      host: "groovyloops.app",
      bucket: "static-beatmaker-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "EVK8C8IQ73AM9",
      faq: {
        enabled: false,
      },
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use-ios.html",
          },
        ],
        [DOCUMENT_TYPES.PRIVACY_POLICY_ANDROID]: [
          {
            path: "privacy-policy-gremafil.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "subscription-terms-android.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE_ANDROID]: [
          {
            path: "terms-of-use-app.html",
          },
        ],
      },
    },
    fasting: {
      name: "Fasting APP (Fast Easy)",
      host: "fasteasy.life",
      bucket: "static-fasting-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E1IL72BTWTOCZ6",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS]: [
          {
            path: "subscription-terms-app.html",
          },
        ],
        [DOCUMENT_TYPES.SUBSCRIPTION_TERMS_ANDROID]: [
          {
            path: "subscription-terms-android.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use-app.html",
          },
        ],
      },
    },
    asoman: {
      name: "ASO. H&F. Men (full-body-workout-for-men.net)",
      host: "full-body-workout-for-men.net",
      bucket: "static-aso-man-landing-prod",
      region: "us-east-2",
      cloudfront_distribution_id: "E2XA4UPU1SAUK8",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use.html",
          },
        ],
      },
    },
    asowoman: {
      name: "ASO. H&F. Women (female-fitness-at-home-no-equipment.net)",
      host: "female-fitness-at-home-no-equipment.net",
      bucket: "static-aso-woman-landing-prod",
      region: "us-east-1",
      cloudfront_distribution_id: "E34LR6L0VKOB6Y",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "privacy-policy.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "terms-of-use.html",
          },
        ],
      }
    },
    web_products_landing: {
      name: "Web Products Landing",
      bucket: "react-web-products-landing-prod",
      host: "web-products-landing.asqq.io",
      region: "us-east-2",
      cloudfront_distribution_id: "E3SR0A852XLGO1",
      documents: {
        [DOCUMENT_TYPES.PRIVACY_POLICY]: [
          {
            path: "policy/privacy-policy.html",
            template: "privacy-policy-template.html",
          },
        ],
        [DOCUMENT_TYPES.TERMS_OF_USE]: [
          {
            path: "policy/terms-of-use.html",
            template: "terms-of-use-template.html",
          },
        ],
      },
    }
    }
    
    // New Bucket Names
    // static-actitechlimited-landing-prod
    // static-afesol-cy-landing-prod
    // static-afesollimited-landing-prod
    // static-amazing-cy-landing-prod
    // static-artlab-front-2-prod
    // static-beatmaker-landing-prod
    // static-calorietracker-landing-2-prod
    // static-engular-web-prod
    // static-fitprism-landing-prod
    // static-fitty-landing-prod
    // static-funplexlimited-landing-prod
    // static-langline-front-stage
    // static-langline-landing-prod
    // static-plextechlimited-landing-prod
    // static-webmc-front-develop
    // static-webmc-front-preprod
    // static-webmc-front-prod
    // static-webmc-front-stage
    // static-willetech-cy-landing-prod

    // Cloudfront distributions

    // EY3UCAFN8OPVG
    // actitechlimited-landing-prod
    //
    // E3268PAC80OE5M
    // afesol-cy-landing-prod
    //
    // E3LZ6DX16UNCQQ
    // afesollimited-landing-prod
    //
    // E2W43RDLSVAXLO
    // amazing-cy-landing-prod
    //
    // EVK8C8IQ73AM9
    // beatmaker-landing-prod
    //
    // ET3SBWGXX590X
    // calorietracker-landing-2-prod
    //
    // E27GBTJDIK5YSY
    // fitprism-landing-prod
    //
    // E7RDYL1BJUK8Y
    // fitty-landing-prod
    //
    // EQ3026XM0NIA0
    // funplexlimited-landing-prod
    //
    // E3EJWF6TVBJYJM
    // plextechlimited-landing-prod
    //
    // ER13RBV28H65W
    // willetech-cy-landing-prod
  };

