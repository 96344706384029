import defaults from "./defaults";
import DocumentEditor from "./controllers/DocumentEditor/DocumentEditor";
import SectionsList from "./controllers/FrequentlyAskedQuestions/SectionsList";

let g = defaults.userGroups;

const dashboardRoutes = [
  // Document editor
  {
    exact: true,
    path: "/document-editor",
    name: "Document Editor",
    icon: "pe-7s-cart",
    component: DocumentEditor,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.viewer, g.support],
  },
  // FAQ editor
  {
    invisible: true,
    exact: true,
    path: "/faq",
    name: "FAQ",
    icon: "pe-7s-headphones",
    component: SectionsList,
    layout: "/admin",
    allowedGroups: [g.admin, g.editor, g.support],
  },
  // {
  //     path: "/products/add",
  //     name: "Add Product",
  //     component: ProductAdd,
  //     layout: "/admin",
  //     invisible: true,
  //     allowedGroups: [g.admin, g.editor]
  // },
  // {
  //     path: "/products/edit/:id",
  //     name: "Edit Product",
  //     component: ProductEdit,
  //     layout: "/admin",
  //     invisible: true,
  //     allowedGroups: [g.admin, g.editor]
  // },
  // // Food
  // {
  //     exact: true,
  //     path: "/food",
  //     name: "Food",
  //     icon: "pe-7s-piggy",
  //     component: Food,
  //     layout: "/admin",
  //     allowedGroups: [g.admin, g.editor, g.viewer]
  // },
  // {
  //     path: "/food/add",
  //     name: "Add Food",
  //     component: FoodAdd,
  //     layout: "/admin",
  //     invisible: true,
  //     allowedGroups: [g.admin, g.editor]
  // },
  // {
  //     path: "/food/edit/:id",
  //     name: "Edit Food",
  //     component: FoodEdit,
  //     layout: "/admin",
  //     invisible: true,
  //     allowedGroups: [g.admin, g.editor]
  // },
  // // Barcode Requests
  // {
  //     exact: true,
  //     path: "/barcode-requests",
  //     name: "Barcode Requests",
  //     icon: "pe-7s-cloud-upload",
  //     component: BarcodeRequest,
  //     layout: "/admin",
  //     allowedGroups: [g.admin, g.editor, g.viewer]
  // },
  // {
  //     path: "/barcode-requests/edit/:id",
  //     name: "Edit Barcode Requests",
  //     component: BarcodeRequestEdit,
  //     layout: "/admin",
  //     invisible: true,
  //     allowedGroups: [g.admin, g.editor]
  // },
  // {
  //     path: "/info",
  //     name: "Info",
  //     icon: "pe-7s-info",
  //     component: Info,
  //     layout: "/admin",
  //     allowedGroups: [g.admin, g.editor, g.viewer, g.support]
  // },
];

export default dashboardRoutes;
